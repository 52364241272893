import { FormInput, Modal } from "@vesatogo/grass-core"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { CustomToast } from "~/components/CustomToast"

export const RemarkModal = ({ useAddRemarkMutation, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get("remark")
  const isOpen = Boolean(id)
  const [remark, setRemark] = useState()

  const [, addRemark] = useAddRemarkMutation()
  const onSubmit = async () => {
    const { data: updateData, error } = await addRemark({
      id: id,
      object: {
        remark: remark,
      },
    })
    if (error) {
      onClose()
      return CustomToast({
        title: "Something went wrong! " + error.message,
        intent: "danger",
      })
    }
    onClose()
    return CustomToast({
      title: "Successfully updated remark!",
    })
  }

  const onClose = () => {
    searchParams.delete("remark")
    setSearchParams(searchParams)
    return
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={props.title}
      bodyClassName="max-w-sm"
      primaryActionButtonProps={{
        text: "Ok",
        onClick: onSubmit,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: onClose,
      }}
    >
      <FormInput
        value={remark}
        onChange={e => setRemark(e)}
        label="Remark"
      ></FormInput>
    </Modal>
  )
}
